import { ActionTypes } from "../constants/action-types";

const initialState={
    imageData:[],
};

const eventImageReducer=(state=initialState,action)=>{
    switch(action.type){
        case ActionTypes.FETCH_EVENT_IMAGE:
          console.log('Reducer received images:', action.payload);
            return{
                ...state,
                imageData:action.payload,
            };
            default:
                return state;
    }
};
export default eventImageReducer;
