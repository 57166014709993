import { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { uploadNewEducation } from '../../../../redux/actions/action'; 
import TextField from "../../../../components/formcomponents/TextField";

const UploadState = () => {
  const dispatch = useDispatch();
  
  const [formData, setFormData] = useState({
    degree_name: '',
    creation_date: '',
  });

  const [validationError, setValidationError] = useState('');
  const { degree_name, creation_date } = formData;
  
  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    setFormData({
      ...formData,
      creation_date: formattedDate
    });
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setValidationError('');
  };
  const educationData = {
    degree_name: formData.degree_name,
    creation_date: formData.creation_date,
  };
 
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!degree_name.trim()) { 
      setValidationError("Degree name is required");
      return;
    }
    dispatch(uploadNewEducation(educationData));
    console.log('formdata', educationData);
  };

  const handleClear = () => {
    setFormData({
      ...formData,
      degree_name: '',
    });
    setValidationError('');
  };

  return (
    <div className="mx-auto max-w-4xl py-8 lg:ml-50 lg:mt-[-90px] xl:top-[210px] xl:ml-[130px] ">
      <h2 className="text-white">Add Education</h2>
      <form onSubmit={handleSubmit}>
        <div className="flex items-center">
          <div className="mr-5 w-60 mt-5">
            <TextField
              label="Degree Name"
              type="text"
              name="degree_name"
              value={degree_name}
              placeholder="Degree Name"
              onChange={handleChange}
            />
            {validationError && (
              <p className="text-red-500 text-sm mt-1">{validationError}</p>
            )}
          </div>
          <div className="mr-5 w-60 mt-5">
            <TextField
              label="Creation Date"
              type="date"
              name="creation_date"
              value={creation_date}
              placeholder="Creation Date" 
              required
              disabled
            />  
          </div>
        </div>
        <div className="mt-5 flex justify-center">
          <button className="mr-5 bg-[#532fa0] text-white py-3 px-10 text-xl rounded" fullWidth type="submit">Save</button>
          <button className="bg-[#532fa0] text-white py-3 px-10 text-xl rounded" fullWidth type="button" onClick={handleClear}>Clear</button>
        </div>
      </form>
    </div>
  );
};

export default UploadState;
