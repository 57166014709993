import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from '../../../../components/tableComponents/Table';
import Dropdown from '../../../../components/dropdown/DropDown';
import { fetchParticipants, sendFeedbackForm, fetchFeedback, fetchCertificate } from '../../../../redux/actions/action';
import ViewParticipants from './ViewParticipants';
import FeedbackForm from './ViewFeedbackForm'; 
import Certificate from '../Certificates/Certificate';

const ParticipantsDetails = () => {
  const dispatch = useDispatch();
  const fetchParticipant = useSelector((state) => state.fetchParticipants.participants);
  const feedback = useSelector((state) => state.fetchFeedback.feedback);
  const certificateData  = useSelector((state) => state.fetchCertificate.certificate);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [filterType, setFilterType] = useState('all');
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [fetchedFeedback, setFetchedFeedback] = useState(null);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [showCertificate, setShowCertificate] = useState(false);

  useEffect(() => {
    dispatch(fetchParticipants());
  }, [dispatch, feedback, certificateData]);

  const columns = useMemo(
    () => [
      {
        Header: 'Full Name',
        accessor: (row) => `${row.firstName} ${row.lastName}`,
      },
      {
        Header: 'Mobile Number',
        accessor: 'mobile',
      },
      {
        Header: 'Email ID',
        accessor: 'email',
      },
      {
        Header: 'Payment Status',
        accessor: 'payment',
      },
      {
        Header: 'Participants Attend (Feedback Link)',
        accessor: 'feedback',
        Cell: ({ row }) => (
          <input
            type="checkbox"
            onChange={(e) => handleCheckboxChange(e, row.original)}
          />
        ),
      },
      {
        Header: 'Received Feedback',
        accessor: 'feedbackReceived',
        Cell: ({ row }) => (
          <input
            type="checkbox"
            onChange={() => handleFeedbackView(row.original)}
          />
        ),
      },      
      {
        Header: 'Feedback (Issue Certificate)',
        accessor: 'hearAboutUs',
        Cell: ({ row }) => (
          <input
            type="radio"
            name="certificate"
            onChange={() => handleCertificateOpen(row.original)} 
          />
        ),
      },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row }) => (
          <Dropdown
            options={['View']}
            onSelect={(option) => handleOptionSelect(option, row.original)}
          />
        ),
      },
    ],
    []
  );

  const handleCertificateOpen = async (participant) => {
    setSelectedParticipant(participant);
    setShowCertificate(true);
    try {
        await dispatch(fetchCertificate(participant.email)); 
    } catch (error) {
        console.error('Error fetching certificate:', error);
    }
  };

  const handleOptionSelect = (option, participant) => {
    console.log('Selected Participant:', participant);
    if (option === 'View') {
      setSelectedParticipant(participant);
      setShowPopup(true);
      setFilterType(participant.event); 
    }
  };

  const handleCheckboxChange = async (e, participant) => {
    if (e.target.checked) {
      try {
        const response = await dispatch(sendFeedbackForm(participant.email, participant.event, participant.firstName));
        const feedbackData = response.payload;
  
        if (feedbackData && feedbackData.length > 0) {
          setFetchedFeedback(feedbackData[0]);
          setSelectedParticipant({ ...participant, feedbackAvailable: true });
          setShowFeedbackForm(true);
        } else {
          console.warn('No feedback available for this participant.');
        }
      } catch (error) {
        console.error('Error sending feedback form:', error);
      }
    }
  };

  const handleFeedbackView = async (participant) => {
    try {
      const response = await dispatch(fetchFeedback(participant.email));
      const feedbackData = response.payload;
  
      if (feedbackData && feedbackData.length > 0) {
        setFetchedFeedback(feedbackData[0]);
        setShowFeedbackForm(true);
        setFeedbackMessage(''); 
      } else {
        setFeedbackMessage('Feedback is not available.');
        setFetchedFeedback(null); 
        setShowFeedbackForm(true); 
      }
    } catch (error) {
      console.error('Error fetching feedback:', error);
      setFeedbackMessage('Error fetching feedback.'); 
      setShowFeedbackForm(true); 
    }
  };  

  const filteredData = useMemo(() => {
    console.log('filterType:', filterType);
    console.log('fetchParticipant:', fetchParticipant);
    if (filterType === 'all') return fetchParticipant;
    return fetchParticipant.filter((participant) => participant.event === filterType);
  }, [fetchParticipant, filterType]);

  return (
    <div>
      <div className="mt-[30px] pl-36 text-black">
        <button
          className={`grow justify-center items-center px-10 pt-2 text-xl text-center rounded-l-xl h-[40px] w-[150px] ${
            filterType === 'Webinar' ? 'bg-white' : 'bg-violet-900'
          }`}
          onClick={() => setFilterType('Webinar')}
        >
          Webinar
        </button>
        <button
          className={`grow justify-center items-center px-6 pt-2 rounded-r-none text-xl w-[150px] h-[40px] ${
            filterType === 'Workshop' ? 'bg-white' : 'bg-violet-900'
          }`}
          onClick={() => setFilterType('Workshop')}
        >
          Workshop
        </button>
        <button
          className={`grow justify-center items-center px-6 pt-2 text-xl rounded-r-xl w-[150px] h-[40px] ${
            filterType === 'Seminar' ? 'bg-white' : 'bg-violet-900'
          }`}
          onClick={() => setFilterType('Seminar')}
        >
          Seminar
        </button>
      </div>
      <Table columns={columns} data={filteredData} />
      {showPopup && (
        <ViewParticipants participant={selectedParticipant} onClose={() => setShowPopup(false)} />
      )}
      {showFeedbackForm && (
        <FeedbackForm feedback={fetchedFeedback} onClose={() => setShowFeedbackForm(false)} feedbackMessage={feedbackMessage} />
      )}
      {showCertificate && selectedParticipant && (
        <Certificate email={selectedParticipant.email} onClose={() => setShowCertificate(false)} />
      )}
    </div>
  );
};

export default ParticipantsDetails;