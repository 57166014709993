import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHref } from 'react-router-dom';
import { receiveFeedback } from '../../../../redux/actions/action';

const FeedbackForm = ({ event }) => {
  const dispatch = useDispatch();
  const [conference_rating, setConferenceRating] = useState(0);
  const [speaker_rating, setSpeakerRating] = useState(0);
  const [platform_ease, setPlatformEase] = useState('');
  const [name, setName] = useState('');
  const [event_name, setEventName] = useState(event || '');
  const [eventDate, setEventDate] = useState('');
  const [organization, setOrganization] = useState('');
  const [profession, setProfession] = useState('');
  const [testimonial, setTestimonial] = useState('');
  const [comments, setComments] = useState('');
  const [email, setEmail] = useState('');
  const feedbackFormUrl = useHref('/feedbackForm');
  const feedbackLink = `${feedbackFormUrl}?event=${event}&email=${email}`;

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const eventParam = params.get('event');
    const emailParam = params.get('email');

    //setEventName(eventParam || '');
    setEmail(emailParam || '');
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const feedbackData = {
      name,
      email,
      event_name,
      eventDate,
      organization,
      profession,
      testimonial,
      conference_rating,
      speaker_rating,
      platform_ease,
      comments,
    };
    dispatch(receiveFeedback(feedbackData));
    setTimeout(() => {
      window.close();
    }, 15000);
  };

  return (
    <div className="bg-color border border-black text-white p-12 max-w-4xl mx-auto">
      <h1 className="text-center text-xl font-bold mb-8">FEEDBACK FORM</h1>
      <form onSubmit={handleSubmit} className="space-y-8">

        <div className="grid grid-cols-3 gap-10 mr-2">
          <div>
            <label className="block mb-2 font-semibold">Name*</label>
            <input
              type="text"
              className="w-full p-3 border border-gray-300 rounded"
              value={name}
              placeholder="Enter your Name"
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block mb-2 font-semibold">Event Name*</label>
            <input
              type="text"
              className="w-full p-3 border border-gray-300 rounded"
              value={event_name}
              placeholder="Enter your Event Name"
              onChange={(e) => setEventName(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block mb-2 font-semibold">Date of the Event*</label>
            <input
              type="date"
              className="w-full p-3 border border-gray-300 rounded"
              value={eventDate}
              onChange={(e) => setEventDate(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-3 gap-10 mr-2">
          <div>
            <label className="block mb-2 font-semibold">Organization*</label>
            <input
              type="text"
              className="w-full p-3 border border-gray-300 rounded"
              value={organization}
              placeholder="Enter your Organization"
              onChange={(e) => setOrganization(e.target.value)}
            />
          </div>
          <div>
            <label className="block mb-2 font-semibold">Profession*</label>
            <input
              type="text"
              className="w-full p-3 border border-gray-300 rounded"
              value={profession}
              placeholder="Enter your Profession"
              onChange={(e) => setProfession(e.target.value)}
            />
          </div>
          <div>
            <label className="block mb-2 font-semibold">Testimonial</label>
            <input
              type="text"
              className="w-full p-3 border border-gray-300 rounded"
              value={testimonial}
              placeholder="Enter your Testimonial"
              onChange={(e) => setTestimonial(e.target.value)}
            />
          </div>
        </div>

        <div>
          <label className="block mb-2 font-semibold">How would you rate the Conference?</label>
          <div className="flex space-x-3">
            {[1, 2, 3, 4, 5].map((star) => (
              <button
                type="button"
                key={star}
                onClick={() => setConferenceRating(star)}
                className={`text-8xl ${conference_rating >= star ? 'text-yellow-500' : 'text-gray-300'} bg-transparent focus:outline-none`}
              >
                ★
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block mb-2 font-semibold">How would you rate the Speaker?</label>
          <div className="flex space-x-3">
            {[1, 2, 3, 4, 5].map((star) => (
              <button
                type="button"
                key={star}
                onClick={() => setSpeakerRating(star)}
                className={`text-8xl ${speaker_rating >= star ? 'text-yellow-500' : 'text-gray-300'} bg-transparent focus:outline-none`}
              >
                ★
              </button>
            ))}
          </div>
        </div>

        <div>
          <label className="block mb-2 font-semibold">Do you find the platform easy to use?</label>
          <div className="flex space-x-6">
            <label>
              <input
                type="radio"
                name="platform_ease"
                value="yes"
                onChange={(e) => setPlatformEase(e.target.value)}
                className="mr-2"
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="platform_ease"
                value="no"
                onChange={(e) => setPlatformEase(e.target.value)}
                className="mr-2"
              />
              No
            </label>
          </div>
        </div>

        <div>
          <label className="block mb-2 font-semibold">Comments*</label>
          <input
            type="text"
            className="w-1/2 p-2 border border-gray-300 rounded"
            value={comments}
            placeholder="Enter your Comments"
            onChange={(e) => setComments(e.target.value)}
          />
        </div>

        <div className="text-center mt-8">
          <button
            type="submit"
            className="w-1/4 p-2 bg-blue-600 text-black rounded"
          >
            OK
          </button>
        </div>
      </form>
    </div>
  );
};

export default FeedbackForm;
