
import { ActionTypes } from "../constants/action-types"; // Ensure ActionTypes import is correct

const initialState = {
  upcomingEvents: [], // Initial state as an empty array
  error: null,
};

const UploadEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_UPCOMING_EVENTS:
      return {
        ...state,
        upcomingEvents: action.payload || [], // Ensure we gracefully handle empty payloads (set to empty array)
        error: null, // Clear any previous error
      };
    case ActionTypes.FETCH_EVENTS_ERROR:
      return {
        ...state,
        upcomingEvents: [], // Reset events on error
        error: action.payload, // Set the error message
      };
    default:
      return state;
  }
};

export default UploadEventReducer;
