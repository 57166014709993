import React from 'react';

const Eventdetails = ({ heading }) => {
  return (
      <div className="text-center md:mt-[-170px] lg:mt-[-220px] xl:mt-[-220px] "> 
        <h1 className="text-white text-29xl font-bold">{heading}</h1> 
    </div>
  );
};

export default Eventdetails;
