// redux/reducers/countdownReducer.js
 import { ActionTypes } from "../constants/action-types";

// const initialState = {
//   countdown: {
//     days: 0,
//     hours: 0,
//     minutes: 0,
//     seconds: 0,
//   },
//   error: null,
// };

// const UploadCountdownReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case ActionTypes.FETCH_COUNTDOWN:
//       return {
//         ...state,
//         countdown: action.payload, // Update the countdown data
//         error: null,
//       };
//     case ActionTypes.COUNTDOWN_ERROR:
//       return {
//         ...state,
//         error: action.payload, // Set the error message
//       };
//     default:
//       return state;
//   }
// };

// export default UploadCountdownReducer;

const initialState = {
  countdown: {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  },
  error: null,
};

const UploadCountdownReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_COUNTDOWN:
      return {
        ...state,
        countdown: action.payload || { days: 0, hours: 0, minutes: 0, seconds: 0 }, // Handle empty payload gracefully
        error: null,
      };
    case ActionTypes.COUNTDOWN_ERROR:
      return {
        ...state,
        countdown: { days: 0, hours: 0, minutes: 0, seconds: 0 }, // Reset countdown on error
        error: action.payload, // Set the error message
      };
    default:
      return state;
  }
};

export default UploadCountdownReducer;
