import { ActionTypes } from "../constants/action-types";

const initialState = {
    certificate: [],
};

export const FetchCertificate = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.FETCH_CERTIFICATE:
      return { ...state, certificate: payload };
    default:
      return state;
  }
};
