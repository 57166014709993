import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from '../../../../components/tableComponents/Table';
import Dropdown from '../../../../components/dropdown/DropDown';
import { fetchAllCertificate } from '../../../../redux/actions/action';
import ViewCertificate from './ViewCertificate';

const EventCertificate = () => {
  const dispatch = useDispatch();
  const fetchCertificateData = useSelector((state) => state.fetchAllCertificate.certificate);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [filterType, setFilterType] = useState('all');

  useEffect(() => {
    dispatch(fetchAllCertificate());
  }, [dispatch]);
  console.log('Fetched Certificate Data:', fetchCertificateData);

  const columns = useMemo(
    () => [
      {
        Header: 'Full Name',
        accessor: 'name',
      },
      {
        Header: 'Event Name',
        accessor: 'event_name',
      },
      {
        Header: 'Event Date',
        accessor: 'eventDate',
        Cell: ({ value }) => new Date(value).toLocaleDateString('en-CA'),
      },
      {
        Header: 'Issue Dates',
        accessor: 'issued_at',
        Cell: ({ value }) => new Date(value).toLocaleDateString('en-CA'),
      },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row }) => (
          <Dropdown
            options={['View']}
            onSelect={(option) => handleOptionSelect(option, row.original)}
          />
        ),
      },
    ],
    []
  );

  const handleOptionSelect = (option, certificate) => {
    console.log('Selected Certificate:', certificate);
    if (option === 'View') {
      setSelectedParticipant(certificate);
      setShowPopup(true);
      setFilterType(certificate.event_name); 
    }
  };

  const filteredData = useMemo(() => {
    if (!fetchCertificateData) return [];
    if (filterType === 'all') return fetchCertificateData;
    return fetchCertificateData.filter(
      (certificate) => certificate.event_name === filterType
    );
  }, [fetchCertificateData, filterType]);
  
  return (
    <div>
      <div className="mt-[30px] pl-36 text-black">
        <button
          className={`grow justify-center items-center px-10 pt-2 text-xl text-center rounded-l-xl h-[40px] w-[150px] ${
            filterType === 'Webinar' ? 'bg-white' : 'bg-violet-900'
          }`}
          onClick={() => setFilterType('Webinar')}
        >
          Webinar
        </button>
        <button
          className={`grow justify-center items-center px-6 pt-2 rounded-r-none text-xl w-[150px] h-[40px] ${
            filterType === 'Workshop' ? 'bg-white' : 'bg-violet-900'
          }`}
          onClick={() => setFilterType('Workshop')}
        >
          Workshop
        </button>
        <button
          className={`grow justify-center items-center px-6 pt-2 text-xl rounded-r-xl w-[150px] h-[40px] ${
            filterType === 'Seminar' ? 'bg-white' : 'bg-violet-900'
          }`}
          onClick={() => setFilterType('Seminar')}
        >
          Seminar
        </button>
      </div>
      <Table columns={columns} data={filteredData} />
      {showPopup && (
        <ViewCertificate certificate={selectedParticipant} onClose={() => setShowPopup(false)} />
      )}
    </div>
  );
};

export default EventCertificate;