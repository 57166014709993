import React from 'react';

const Card = ({ title, description }) => {
  return (
    <div className=" w-[220px] border rounded-xl pt-10 bg-white shadow-md lg:h-[115px] lg:w-[150px] ml-[270px]  ">
      <h2 className=" text-29xl lg:text-13xl lg:font-semibold font-poppins mt-[-42px] text-center ">{title}</h2>
      <p className="text-center text-29xl font-bold font-poppins mt-[-7px]">{description}</p>
    </div>
  );
};

export default Card;

