import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchCertificate } from "../../../../redux/actions/action";
import { useParams } from "react-router-dom";

const Certificate = () => {
  const { email } = useParams();
  const dispatch = useDispatch();
  const certificateData = useSelector((state) => state.fetchCertificate.certificate);
  //console.log('Certificate Data:', certificateData);
  useEffect(() => {
    if (email) {
      console.log('Dispatching fetchCertificate with email:', email);
      dispatch(fetchCertificate(email)); 
    }
  }, [dispatch, email]);

  //const certificate = certificateData && certificateData[0];
  const certificate = Array.isArray(certificateData) ? certificateData[0] : certificateData;
  console.log('Certificate Data:', certificate);

  if (!certificate) {
    return <p>Loading certificate data...</p>;
  }

  const { name, event_name, eventDate } = certificate;

  return (
    <div className="bg-gray-100 min-h-screen flex items-center justify-center text-black">
      <div className="w-full bg-white shadow-lg overflow-hidden border-4 border-blue-700">
      
        <div className="relative h-28 overflow-hidden">
          <div className="absolute inset-y-0 left-0 w-1/3 mt-14 bg-color" style={{clipPath: 'polygon(0 100%, 0 0, 100% 100%)'}}></div>
          <div className="absolute inset-y-0 right-0 w-1/3 mt-14 bg-color" style={{clipPath: 'polygon(0 100%, 100% 0,100% 100%)'}}></div>
          <div className="bg-[#090119] text-white py-6 px-8 flex items-center justify-center">
            <img
              className="h-16"
              loading="lazy"
              alt="InnoHub Logo"
              src="/poplogo.svg"
            />
          </div>
        </div>

        <div className="flex justify-between items-start p-4 ml-4">
          <div className="w-1/3 flex flex-col justify-center">
            <img
              className="h-30 w-20 object-cover ml-12 -mt-4"
              loading="lazy"
              alt="Description of Image"
              src="image 1.png" 
              style={{
                borderLeft: "1px solid black",  
                borderRight: "1px solid black", 
                borderBottom: "1px solid black", 
              }}
            />
            <div className="w-20 h-40 bg-midnightblue ml-12" style={{borderLeft: "1px solid black", borderRight: "1px solid black"}}></div>
            <div className="w-20 h-20 ml-12 bg-color" style={{ clipPath: 'polygon(50% 100%, 100% 0, 0 0)', backgroundColor: 'color', boxShadow: '0 0 0 2px color'}}></div>
          </div>

          <div className="flex flex-col w-2/3 mr-4">

            <div className="p-10 bg-white">
              <h1 className="text-4xl font-bold uppercase text-color text-left mb-6">
                Participation Certificate
              </h1>
              <p className="text-lg leading-relaxed">
                This is to certify that{" "}
                <span className="font-bold text-xl underline">{name}</span> has
                successfully completed the{" "}
                <span className="font-semibold underline">{event_name}</span> held on{" "}
                <span className="font-bold underline">{new Date(eventDate).toLocaleDateString()}</span>, organized by the{" "}
                <span className="">InnoHub Research Center</span>, Bangalore.
              </p>

              <p className="mt-6">
                We acknowledge the dedication and effort shown by the participant
                during the event.
              </p>

              <div className="flex justify-between items-center mt-12">
                <div className="text-center">
                  <p className="font-bold text-color">SIGNATURE</p>
                  <div className="mt-2 border-t-2 border-gray-400 w-40 mx-auto"></div>
                </div>
                <div className="text-center">
                  <p className="font-bold">[Name of the Signatory]</p>
                  <p className="italic font-bold text-gray-600">[Title/ Position]</p>
                  <div className="mt-2 border-t-2 border-gray-400 w-40 mx-auto"></div>
                </div>
                <div class Nam ="text-center">
                  <p className="font-bold">Date of Issue</p>
                  <div className="mt-2 border-t-2 border-gray-400 w-40 mx-auto"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p className="text-center mt-8 text-blue-800 ">
          https://innohubrc.com/
        </p>

        <div className="relative h-14 overflow-hidden">
          <div className="absolute inset-y-0 left-0 w-1/3 bg-color" style={{clipPath: 'polygon(0 100%, 0 0, 100% 100%)'}}></div>
          <div className="absolute inset-y-0 right-0 w-1/3 bg-color" style={{clipPath: 'polygon(0 100%, 100% 0,100% 100%)'}}></div>
          <div className="bg-white text-white py-6 px-8 flex items-center justify-center">
            <p className="text-sm">Copyright 2023 InnoHub Research Center</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certificate;
