import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateStudentById, fetchStates, fetchCities } from '../../redux/actions/action';

  const EditStudent = ({ student, onClose, onSubmit }) => {
  const [editedStudent, setEditedStudent] = useState(student);
  const [selectedState, setSelectedState] = useState(student.state || '');
  const [selectedCity, setSelectedCity] = useState(student.city || '');
  const dispatch = useDispatch();
  const states = useSelector((state) => Array.isArray(state.states.states) ? state.states.states : []);
  const cities = useSelector((state) => Array.isArray(state.cities.cities) ? state.cities.cities : []);
  const [contactNumberError, setContactNumberError] = useState('');
  const [testScoreError, setTestScoreError] = useState('');
  const [interviewScoreError, setInterviewScoreError] = useState('');

  useEffect(() => {
    dispatch(fetchStates());
  }, [dispatch]);

  useEffect(() => {
    if (selectedState) {
      dispatch(fetchCities(selectedState))
        .catch(error => console.error('Error fetching cities:', error));
    }
  }, [dispatch, selectedState]);

  useEffect(() => {
    if (student) {
      setEditedStudent(student);
      setSelectedState(student.state || '');
      setSelectedCity(student.city || '');
    }
  }, [student]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedStudent((prevStudent) => ({
      ...prevStudent,
      [name]: value,
    }));
    if (name === 'mobile_no') {
      const validationError = validateContactNumber(value);
      setContactNumberError(validationError);
    }
    if (name === 'test_score') {
      const score = parseInt(value, 10);
      if (score < 0 || score > 40) {
        setTestScoreError('Test Score must be between 0 and 40.');
      } else {
        setTestScoreError('');
      }
    }
    // Validate interview score
    if (name === 'interview_score') {
      const score = parseInt(value, 10);
      if (score < 0 || score > 10) {
        setInterviewScoreError('Interview Score must be between 0 and 10.');
      } else {
        setInterviewScoreError('');
      }
    }
  };

  const handleSubmit = () => {
    const { student_id, ...updatedData } = editedStudent;
    const stateName = states.find(state => state.state_id === parseInt(selectedState))?.state_name || '';
    const cityName = cities.find(city => city.city_id === parseInt(selectedCity))?.city_name || '';
    const contactNumberValidation = validateContactNumber(editedStudent.mobile_no);
    if (contactNumberValidation) {
      setContactNumberError(contactNumberValidation);
      return; 
    } else {
      setContactNumberError(''); 
    }
    if (testScoreError || interviewScoreError) {
      return; 
    }
    dispatch(updateStudentById({ ...updatedData, state: stateName, city: cityName }, student_id))
      .then((updatedStudent) => {
        onSubmit(updatedStudent);
        onClose();
      })
      .catch((error) => {
        console.error('Error updating student:', error);
      });
  };
  const handleStateChange = (e) => {
    const newState = e.target.value;
    setSelectedState(newState);
    setSelectedCity('');
  };
  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };

  const validateContactNumber = (number) => {
    const regex = /^\d{10}$/; 
    if (!regex.test(number)) {
      return 'Contact Number must contain exactly 10 digits.';
    }
    return '';
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-10 pr-10 rounded-lg max-w-lg">
        <h2 className="text-lg font-semibold mb-4">Edit Student</h2>
        <label className="block mb-2">
          Address:
          <input type="text"
          name="address_line1" 
          value={editedStudent.address_line1} 
          onChange={handleInputChange}
          className="block w-[247px] border border-gray-300 rounded-md px-1 py-2  text-sm" />
        </label>
        <label className="block mb-2">
          State:
            <div className="bg-white rounded-lg border border-gray-300">
              <select
                className="w-64 border border-gray-300 rounded-md px-1 py-2 text-sm"
                value={selectedState}
                onChange={handleStateChange}>
                <option value="">Select State</option>
                {states.filter(state => state.status === 0).map(state => (
                  <option key={state.state_id} value={state.state_id}>
                    {state.state_name}
                  </option>
                ))}
              </select>
            </div>
          </label>
          <label className="block mb-2">
            City:
            <div className="bg-white rounded-lg border border-gray-300">
              <select
                className="w-64 border border-gray-300 rounded-md px-1 py-2 text-sm"
                value={selectedCity}
                onChange={handleCityChange}>
                <option value="">Select City</option>
                {cities.filter(city => city.status === 0).map(city => (
                  <option key={city.city_id} value={city.city_id}>
                    {city.city_name}
                  </option>
                ))}
              </select>
            </div>
          </label>
        
        <label className="block mb-2">
          Mobile:
          <input type="text" 
          name="mobile_no" 
          value={editedStudent.mobile_no} 
          onChange={handleInputChange}
          error={contactNumberError}
          className="block w-[247px] border border-gray-300 rounded-md px-1 py-2  text-sm"
          />
        </label>
        {contactNumberError && (<div className="text-xs text-red-500 mt-1">{contactNumberError}</div>)}
        <label className="block mb-2">
          Test Score (0-40):
          <input type="number" 
          name="test_score" 
          value={editedStudent.test_score} 
          onChange={handleInputChange}
          min={0}
          max={40}
          error={testScoreError}
          className="block w-[247px] border border-gray-300 rounded-md px-1 py-2  text-sm" />
        </label>
        {testScoreError && ( <div className="text-xs text-red-500 mt-1">{testScoreError}</div>)}
        <label className="block mb-2">
          Interview Score (0-10):
          <input type="number" 
          name="interview_score" 
          value={editedStudent.interview_score} 
          onChange={handleInputChange}
          min={0}
          max={10}
          error={interviewScoreError}
          className="block w-[247px] border border-gray-300 rounded-md px-1 py-2  text-sm" />
        </label>
        {interviewScoreError && (<div className="text-xs text-red-500 mt-1">{interviewScoreError}</div>)}
        <div className="flex justify-end pt-6">
          <button onClick={handleSubmit} className="bg-color text-white px-4 py-2 rounded-md mr-2">Submit</button>
          <button onClick={onClose} className="bg-color text-white px-4 py-2 rounded-md">Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default EditStudent;

