import { ActionTypes } from "../constants/action-types";

const initialState = {
    EventImageStaus:[],
};

export const UploadEventImage = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.UPLOAD_EVENT_IMAGE:
      return { ...state, EventImageStaus: payload };
    default:
      return state;
  }
};