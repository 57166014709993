import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../Header';
import Footer from '../Footer';
import { fetchAllCertificate } from '../../redux/actions/action';

function Archive() {
  const dispatch = useDispatch();
  const fetchCertificateData = useSelector((state) => state.fetchAllCertificate.certificate);
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);
  const [currentFeedbackIndex, setCurrentFeedbackIndex] = useState(0);

  useEffect(() => {
    dispatch(fetchAllCertificate()).catch((error) => {
      console.error("Error fetching data:", error);
    });
  }, [dispatch]);

  const handleNext = () => {
    if (fetchCertificateData) {
      setCurrentTestimonialIndex((prevIndex) =>
        prevIndex === fetchCertificateData.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const handlePrevious = () => {
    if (fetchCertificateData) {
      setCurrentTestimonialIndex((prevIndex) =>
        prevIndex === 0 ? fetchCertificateData.length - 1 : prevIndex - 1
      );
    }
  };

  const handleNextFeedback = () => {
    if (fetchCertificateData) {
      setCurrentFeedbackIndex((prevIndex) =>
        prevIndex === fetchCertificateData.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  const handlePreviousFeedback = () => {
    if (fetchCertificateData) {
      setCurrentFeedbackIndex((prevIndex) =>
        prevIndex === 0 ? fetchCertificateData.length - 1 : prevIndex - 1
      );
    }
  };

  return (
    <div className="relative bg-gray xl:w-full lg:w-full md:w-full w-full px-0 mx-auto">
      <div className="absolute top-[95px] right-0 left-0 h-[566px] [background:linear-gradient(180deg,_#09011a,_rgba(105,_63,_196,_0.57)_43%,_rgba(163,_134,_223,_0)_91%)] overflow-hidden z-0"></div>
      <div className="relative mx-auto pt-5 font-poppins z-10">
        <Header />
        <main className="container mx-auto mt-24 px-4 sm:px-6 lg:px-8">
          <section className="my-8 px-4 sm:px-6 md:px-8 lg:px-14 text-white">
          <h1 className="text-17xl sm:text-3xl md:text-5xl lg:text-17xl mb-10 flex items-center justify">
            <span className="font-bold mr-8 mb-2">Archive</span>
            <img src="Vector3.png" alt="Image" className="mx-4" />
            <a 
              href="#" 
              className="text-lg sm:text-xl md:text-2xl lg:text-lg text-white hover:underline m-0 p-0"
            >
              Click Here
            </a>
          </h1>
            <p className="text-13xl sm:text-lg md:text-xl lg:text-13xl font-bold mt-10 mb-8">Testimonials:</p>
            {fetchCertificateData && fetchCertificateData.length > 0 && (
              <div className="p-4 mb-4 rounded-lg">
                <p className="text-lg sm:text-xl md:text-2xl lg:text-5xl">
                  {fetchCertificateData[currentTestimonialIndex].testimonial}
                </p>
                <div className="flex justify-between items-center mt-2">
                  <div>
                    <p>{fetchCertificateData[currentTestimonialIndex].name}</p>
                    <p>{new Date(fetchCertificateData[currentTestimonialIndex].issued_at).toLocaleDateString()}</p>
                  </div>
                  <div className="flex">
                    <button onClick={handlePrevious} className="bg-white text-black px-4 py-2 font-bold rounded">
                      &#11104;
                    </button>
                    <button onClick={handleNext} className="bg-white text-black px-4 py-2 ml-4 font-bold rounded">
                      &#x279E;
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className="border-t border-white my-8" style={{ height: '2px', backgroundColor: 'white' }}></div>

            <p className="text-13xl sm:text-lg md:text-xl lg:text-13xl font-bold mt-16 mb-8">Feedback:</p>
            {fetchCertificateData && fetchCertificateData.length > 0 && (
              <div className="p-4 mb-4 rounded-lg">
                <p className="text-lg sm:text-xl md:text-2xl lg:text-5xl">{fetchCertificateData[currentFeedbackIndex].comments}</p>
                <div className="flex justify-between items-center mt-2">
                  <div>
                    <p>{fetchCertificateData[currentFeedbackIndex].name}</p>
                    <p>{new Date(fetchCertificateData[currentFeedbackIndex].issued_at).toLocaleDateString()}</p>
                  </div>
                  <div className="flex">
                    <button onClick={handlePreviousFeedback} className="bg-white text-black px-4 py-2 font-bold rounded">
                      &#11104;
                    </button>
                    <button onClick={handleNextFeedback} className="bg-white text-black px-4 py-2 ml-4 font-bold rounded">
                      &#x279E;
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className="border-t border-white my-8" style={{ height: '2px', backgroundColor: 'white' }}></div>
          </section>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Archive;
