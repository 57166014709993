import React from 'react';

const ViewParticipants = ({ certificate, onClose }) => {
    console.log('certificate:', certificate);
    if (!certificate) return null; 

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-color pl-8 rounded-lg shadow-lg w-full max-w-2xl ml-24 mt-28 border-4 border-white border-solid">
      <h2 className="text-2xl mb-4">Certificate  Details</h2>
      <div className="grid grid-cols-2">
        <p><strong>Full Name:</strong> {certificate.name}</p>
        <p><strong>Email:</strong> {certificate.email}</p>
        <p><strong>Mobile:</strong> {certificate.mobile}</p>
        <p><strong>Event Name:</strong> {certificate.event_name}</p>
        <p><strong>Testimonial:</strong> {certificate.testimonial}</p>
        <p><strong>Comments:</strong> {certificate.comments}</p>
        <p><strong>Event Date:</strong> {new Date(certificate.eventDate).toLocaleDateString()}</p>
        <p><strong>Issued At:</strong>{new Date(certificate.issued_at).toLocaleString()}</p>
        </div>
        <div className="mt-6 flex justify-end mb-4 mr-4">
          <button onClick={onClose} className="bg-black text-white py-2 px-6 rounded-lg">
            Close
          </button>
        </div>
        </div>
    </div>
  );
};

export default ViewParticipants;