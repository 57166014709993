import React from 'react';

const FeedbackForm = ({ feedback, onClose, feedbackMessage }) => {
  if (feedbackMessage) {

    return (
      <div className="bg-color border border-black text-white p-12 max-w-4xl mx-auto">
        <p className="text-center">{feedbackMessage}</p>
        <div className="text-center mt-8">
          <button
            type="button"
            className="w-1/4 p-2 bg-blue-600 text-black rounded"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-color border border-black text-white p-12 max-w-4xl mx-auto">
      <h1 className="text-center text-xl font-bold mb-8">VIEW FEEDBACK FORM</h1>
      <div className="space-y-8">
        <div className="grid grid-cols-3 gap-10 mr-2">
          <div>
            <label className="block mb-2 font-semibold">Name:</label>
            <p>{feedback.name}</p>
          </div>
          <div>
            <label className="block mb-2 font-semibold">Event Name:</label>
            <p>{feedback.event_name}</p>
          </div>
          <div>
            <label className="block mb-2 font-semibold">Date of the Event:</label>
            <p>{feedback.eventDate}</p>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-10 mr-2">
          <div>
            <label className="block mb-2 font-semibold">Organization:</label>
            <p>{feedback.organization}</p>
          </div>
          <div>
            <label className="block mb-2 font-semibold">Profession:</label>
            <p>{feedback.profession}</p>
          </div>
          <div>
            <label className="block mb-2 font-semibold">Testimonial:</label>
            <p>{feedback.testimonial}</p>
          </div>
        </div>

        <div>
          <label className="block mb-2 font-semibold">Conference Rating:</label>
          <div className="flex space-x-3">
            {[1, 2, 3, 4, 5].map((star) => (
              <span
                key={star}
                className={`text-8xl ${feedback.conference_rating >= star ? 'text-yellow-500' : 'text-gray-300'} bg-transparent`}
              >
                ★
              </span>
            ))}
          </div>
        </div>

        <div>
          <label className="block mb-2 font-semibold">Speaker Rating:</label>
          <div className="flex space-x-3">
            {[1, 2, 3, 4, 5].map((star) => (
              <span
                key={star}
                className={`text-8xl ${feedback.speaker_rating >= star ? 'text-yellow-500' : 'text-gray-300'} bg-transparent`}
              >
                ★
              </span>
            ))}
          </div>
        </div>

        <div>
          <label className="block mb-2 font-semibold">Platform Usability:</label>
          <p>{feedback.platform_ease}</p>
        </div>

        <div>
          <label className="block mb-2 font-semibold">Comments:</label>
          <p>{feedback.comments}</p>
        </div>

        <div className="text-center mt-8">
          <button
            type="button"
            className="w-1/4 p-2 bg-blue-600 text-black rounded"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeedbackForm;
