import { ActionTypes } from "../constants/action-types";

const initialState = {
    loading: false,
    success: false,
    error: null,
  };
  
  export const feedbackFormReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case ActionTypes.SEND_FEEDBACK_FORM_REQUEST:
      case ActionTypes.RECEIVE_FEEDBACK_REQUEST:
        return { ...state, loading: true, error: null };
  
      case ActionTypes.SEND_FEEDBACK_FORM_SUCCESS:
      case ActionTypes.RECEIVE_FEEDBACK_SUCCESS:
        return { ...state, loading: false, success: true };
  
      case ActionTypes.SEND_FEEDBACK_FORM_FAILURE:
      case ActionTypes.RECEIVE_FEEDBACK_FAILURE:
        return { ...state, loading: false, error: payload };
  
      default:
        return state;
    }
  };
